<template>
  <div>
    <v-form v-model="mainFormValid" class="pa-4">
      <v-text-field
        v-model="internalValue.name"
        :rules="$options.rules.name"
        label="Nom"
        type="text"
        @input="change"
      />

      <ag-autocomplete
        v-model="internalValue.documents"
        :rules="$options.rules.documents"
        multiple
        chips
        deletable-chips
        label="Document"
        :item-list="documentList"
        item-text="name"
        item-key="_id"
        @input="change"
      />

      <ag-autocomplete
        v-model="internalValue.partType"
        :rules="$options.rules.partType"
        label="Type de référence"
        :item-list="partTypeList"
        item-text="name"
        @input="change"
      />

      <ag-autocomplete
        v-if="partsNumberAllowedRequire"
        v-model="internalValue.partsNumberAllowed"
        :rules="$options.rules.partsNumberAllowed"
        :label="partsNumberAllowedLabel"
        :item-list="partsNumberAllowedList"
        item-text="name"
        multiple
        chips
        deletable-chips
        clearable
        @input="change"
      />

      <v-text-field
        v-model="internalValue.timer"
        :rules="$options.rules.timer"
        label="(Timer inter-gamme) Nbre d'heure minimum avant utilisation d'une ressource"
        type="number"
        @input="change"
      />

      <v-text-field
        v-if="isFaisceau"
        v-model="internalValue.faisceauxNumber"
        :rules="$options.rules.faisceauxNumber"
        label="Nombre de faisceaux/latte"
        type="number"
        @input="change"
      />

      <ag-switch
        v-if="isFaisceau"
        v-model="internalValue.setUseWhenIsFinished"
        inset
        label="Déclarer le faisceau consommé une fois finis"
        @input="change"
      />

      <ag-switch
        v-if="isModule"
        v-model="internalValue.bigModule"
        inset
        label="Grands module"
        @input="change"
      />

      <ag-switch
        v-if="!isBobine"
        v-model="internalValue.forceDefinefirstSerialNumber"
        inset
        label="Rendre obligatoire la saisie du premier numéro de série de l'OF"
        @input="change"
      />

      <v-text-field
        v-model="internalValue.stockRejectionCost"
        label="Coût en cas de rebut en stock"
        type="number"
        @input="change"
      />
      <v-text-field
        v-model="internalValue.filtrationSurface"
        label="Surface de filtration"
        type="number"
        @input="change"
      />
    </v-form>
  </div>
</template>

<script>
import Repositories from 'src/repositories'
import Helpers from 'src/utils/helpers'
import FormRules from 'src/utils/form-rules'
import AgSwitch from 'src/components/common/AgSwitch'
import AgAutocomplete from 'src/components/common/AgAutocomplete'

export default {
  name: 'PartNumberDetailsForm',
  components: { AgSwitch, AgAutocomplete },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    internalValue: null,
    mainFormValid: false,
    partTypeList: [],
    partNumberList: [],
    documentList: [],
  }),
  computed: {
    isValidPartNumber () {
      return this.mainFormValid
    },
    isBobine () {
      return this.internalValue.partType === '5b4c8c911d33360c2526d87d'
    },
    isFaisceau () {
      return this.internalValue.partType === '5b4c8cae1d33360c2526d87e'
    },
    isModule () {
      return this.internalValue.partType === '5b4c8cb61d33360c2526d87f'
    },
    partsNumberAllowedRequire () {
      return (this.isBobine || this.isFaisceau || this.isModule)
    },
    partsNumberAllowedLabel () {
      if (this.isBobine) return 'Collodion à utiliser'
      if (this.isFaisceau) return 'Bobines à utiliser'
      if (this.isModule) return 'Faisceaux ou Bobines à utiliser'
      return ''
    },
    partsNumberAllowedList () {
      if (this.isBobine) return this.partNumberList.filter(partNumber => partNumber.partType._id === '5b5ae42c4c6fcbf9c3b6123a')
      if (this.isFaisceau) return this.partNumberList.filter(partNumber => partNumber.partType._id === '5b4c8c911d33360c2526d87d')
      if (this.isModule) return this.partNumberList.filter(partNumber => (partNumber.partType._id === '5b4c8cae1d33360c2526d87e' || partNumber.partType._id === '5b4c8c911d33360c2526d87d'))
      return []
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.internalValue = Helpers.clone(value)
        this.$emit('validate', this.isValidPartNumber)
      },
    },
    isValidPartNumber: {
      immediate: true,
      handler () {
        this.$emit('validate', this.isValidPartNumber)
      },
    },
  },
  async mounted () {
    const [partTypeList, partNumberList, documentList] = await Promise.all([
      await Repositories.partType.getList(),
      await Repositories.partNumber.getList({ partType: ['5b5ae42c4c6fcbf9c3b6123a', '5b4c8c911d33360c2526d87d', '5b4c8cae1d33360c2526d87e'] }),
      await Repositories.document.getList(),
    ])
    this.partTypeList = partTypeList
    this.partNumberList = partNumberList
    this.documentList = documentList
  },
  methods: {
    change () {
      this.$emit('input', this.internalValue)
      this.$emit('validate', this.isValidPartNumber)
      this.$emit('edit')
    },
  },
  rules: {
    name: [
      FormRules.required('requis'),
    ],
    partType: [
      FormRules.required('requis'),
    ],
    partsNumberAllowed: [
      FormRules.required('requis'),
    ],
    timer: [
      FormRules.required('requis'),
      FormRules.greater('Veuilez saisir une valeur positive', { number: -1 }),
      FormRules.integer('Veuilez saisir une valeur entière'),
    ],
    faisceauxNumber: [
      FormRules.required('requis'),
      FormRules.greater('Veuilez saisir une valeur positive', { number: 0 }),
      FormRules.integer('Veuilez saisir une valeur entière'),
    ],
  },
}
</script>
